<template>
  <NuxtLoadingIndicator color="black" />
  <NuxtLayout class="bg-grid-dashed">
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
const { setUser } = useUser();
useHead({
  script: [
    {
      src: 'https://cloud.umami.is/script.js',
      'data-website-id': '6250abec-ac79-4d2e-acfc-5f8619a55f60',
      async: true,
    }
  ],

});

onMounted(() => {
  const userData = localStorage.getItem('blog_user')
  if (userData) setUser(JSON.parse(userData));
})
</script>
