import { default as articlesO1LLmkBPuMeta } from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/article.vue?macro=true";
import { default as categoryXlHzCXRypFMeta } from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/category.vue?macro=true";
import { default as indexgfeO1ylF0mMeta } from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_936fKx6a8wqNMeta } from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/m/[...slug].vue?macro=true";
import { default as memos4nkY3bdNOFMeta } from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/memos.vue?macro=true";
import { default as message2B7LMlyF55Meta } from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/message.vue?macro=true";
import { default as _91_46_46_46slug_935yl79Np6H1Meta } from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/post/[...slug].vue?macro=true";
import { default as setting8rvTeDha3gMeta } from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/setting.vue?macro=true";
export default [
  {
    name: "article",
    path: "/article",
    component: () => import("/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/article.vue")
  },
  {
    name: "category",
    path: "/category",
    component: () => import("/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/category.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/index.vue")
  },
  {
    name: "m-slug",
    path: "/m/:slug(.*)*",
    component: () => import("/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/m/[...slug].vue")
  },
  {
    name: "memos",
    path: "/memos",
    component: () => import("/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/memos.vue")
  },
  {
    name: "message",
    path: "/message",
    component: () => import("/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/message.vue")
  },
  {
    name: "post-slug",
    path: "/post/:slug(.*)*",
    component: () => import("/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/post/[...slug].vue")
  },
  {
    name: "setting",
    path: "/setting",
    component: () => import("/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/pages/setting.vue")
  }
]