import revive_payload_client_4sVQNw7RlN from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_OVoKJro5pc from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import toast_client_9z8IIRO0Dx from "/Users/2.0gnak/code/zzstudio/approved/blog.zzaoclub/plugins/toast.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  toast_client_9z8IIRO0Dx
]